// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN BADGE */

.ant-badge-count {
  font-family: inherit;
}
.ant-badge-status-text {
  margin-left: 3px;
}
.ant-badge-status-processing:after {
  -webkit-animation: none;
  animation: none;
}
