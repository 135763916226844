@charset "UTF-8";
.thumbnail {
  display: inline-block;
  width: 4.28rem;
  height: 4.28rem;
  border: 1px solid #e4e9f0;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #fff;
  text-align: center; }
  .thumbnail img {
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    vertical-align: middle; }

.customCSS {
  background: #08f !important;
  color: white !important; }

.crypto {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }
  @media (max-width: 991px) {
    .crypto {
      flex-direction: column; } }

.table :global(.ant-table) {
  color: #0e0b20; }

.table :global(.ant-pagination) {
  margin-bottom: 0; }

.listMobile {
  display: none;
  margin-bottom: 2.3rem; }
  @media (max-width: 991px) {
    .listMobile {
      display: block; } }

.list {
  width: 200px;
  flex-shrink: 0; }
  @media (max-width: 991px) {
    .list {
      display: none; } }

.listTitle {
  font-weight: bold;
  display: block; }

.listItem {
  display: block;
  background: #fff;
  padding: 0.76rem 1.53rem;
  margin-bottom: 0.76rem;
  border-radius: 5px;
  color: #0e0b20;
  transition: all 0.1s ease-in-out; }
  .listItem:after {
    clear: both;
    content: '';
    display: block; }

.onlyHover {
  display: block;
  background: #fff;
  padding: 0.76rem 1.53rem;
  margin-bottom: 0.76rem;
  border-radius: 5px;
  color: #0e0b20;
  transition: all 0.1s ease-in-out; }
  .onlyHover:hover {
    background: #0190fe;
    color: #fff; }
    .onlyHover:hover .listPercents span {
      color: #fff !important; }

.listItemStable .listPercents span {
  color: #000; }
  .listItemStable .listPercents span:after {
    content: ' ';
    font-family: monospace;
    font-weight: bold; }

.listItemNegative .listPercents span {
  color: #f75535; }
  .listItemNegative .listPercents span:after {
    content: '↓';
    font-family: monospace;
    font-weight: bold; }

.listCurrency {
  float: left; }
  .listCurrency span {
    display: block;
    font-weight: bold; }

.listPercents {
  float: right;
  text-align: right; }
  .listPercents span {
    display: block;
    font-weight: bold;
    color: #00a45b;
    transition: all 0.1s ease-in-out; }
    .listPercents span:after {
      content: ' ↑';
      font-family: monospace;
      font-weight: bold; }

.content {
  margin-left: 30px;
  flex-grow: 1; }
  @media (max-width: 991px) {
    .content {
      margin-left: 0; } }

.warning {
  padding-top: 1.53rem !important;
  padding-bottom: 1.53rem !important;
  cursor: pointer; }

.order {
  display: flex; }
  @media (max-width: 1199px) {
    .order {
      display: block; } }

.orderLeft {
  flex-grow: 1; }

.orderMiddle {
  width: 19.23rem;
  padding: 0 2.3rem; }
  @media (max-width: 1199px) {
    .orderMiddle {
      width: auto;
      max-width: 350px;
      margin: 1.53rem auto 3.07rem; } }

.orderRight {
  flex-grow: 1; }

.form :global(.ant-form-item) {
  margin-bottom: 0.38rem; }

.formLabel {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.92rem; }

.metric_table .ant-table-thead {
  display: none; }

.noDataDiv {
  display: none;
  height: 100%;
  background: #f2f4f8aa;
  position: absolute;
  width: 100%;
  top: 0;
  display: flex;
  align-items: normal;
  justify-content: center;
  right: 0;
  z-index: 10000; }

.noData {
  font-size: 36px;
  margin-top: 20vh;
  position: relative;
  max-width: 50%;
  text-align: center; }
  @media (max-width: 850px) {
    .noData {
      width: auto;
      max-width: 80% !important;
      font-size: 24px; } }

.float {
  position: fixed;
  bottom: 100px;
  right: 50px;
  background-color: #0c9;
  color: #fff;
  z-index: 1010;
  cursor: pointer; }

.my_float {
  margin: 0 auto; }

#innerBox {
  background: #f4f6f9c9;
  height: 100%;
  width: 100%;
  opacity: 1;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  transition: opacity 0.5s; }
  #innerBox p {
    line-height: 0;
    text-align: center; }

.calendarFixedButton {
  margin: 10px 0px;
  background: #161646 !important;
  border: none;
  border-radius: 50px;
  padding: 10px 26px; }

.footer {
  align-self: flex-end;
  width: 100%;
  color: #74708d;
  padding-bottom: 0; }
  @media (max-width: 991px) {
    .footer {
      padding: 3.07rem 1.53rem 3.07rem;
      padding-bottom: 0; } }
